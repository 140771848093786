import React from 'react';
import './assets/style/style.css';
import './assets/icons/font-awesome/css/all.css';
import { firestore } from './components/firebase/firebase';
import Eventlist from './components/eventlist';
import Event from './components/event';
import { Helmet } from "react-helmet";

class Ppsite extends React.Component {

render() {	
	return (
		<div className="app">
			<Helmet>
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
				<meta name="theme-color" content="#f7f7f7" />
			</Helmet>						
			{this.state.initState === 'select' ? <div className="eventlist"><Eventlist eventArray={this.state.eventsList} selectEvent={this.selectEvent} goDemo={this.goDemo} /></div> :null}
			{this.state.initState === 'event' ? <div className="event"><Event cardID={this.state.cardID} eventData={this.state.eventData} getEvents={this.getEvents} /></div> :null}							
			<div className="footer">
				<p>2024 Partypay B.V.</p>
				<div className="conditionContainer">
					<a href="https://partypay.nl/voorwaarden.pdf"><h4>Algemene voorwaarden, verwerkersovereenkomst, SLA</h4></a>
					<a href="https://partypay.nl/privacyverklaring.pdf"><h4>Privacy Policy</h4></a>
				</div>
			</div>		
		</div>
	)
}  
	
constructor(props) {super(props)}	

state = {eventsList: [], eventData: {}, initState: 'select', cardID: ''}

componentDidMount(){
	var searchParams = new URL(window.location.href).searchParams;
	var lastEvent = localStorage.getItem('lastevent');
	var lastVisit = localStorage.getItem('lastvisit');
	if(searchParams.has("id")){
		this.setState({cardID: searchParams.get("id")});
	}
	if(searchParams.has("evt")){
		firestore.collection("events").doc(searchParams.get("evt")).get().then(doc => {var eventData = doc.data(); eventData.eventid = doc.id; this.selectEvent(eventData)});
	}
	else if(lastEvent && lastVisit && lastVisit < (Math.round(new Date().getTime() / 1000) + 172800)){
		firestore.collection("events").doc(lastEvent).get().then(doc => {var eventData = doc.data(); eventData.eventid = doc.id; this.selectEvent(eventData)});
	}
	else{
		//this.getEvents();
		firestore.collection("events").doc('K3DN8ea5yYYdURkXWiKk').get().then(doc => {var eventData = doc.data(); eventData.eventid = doc.id; this.selectEvent(eventData)});
	}
	localStorage.setItem('lastvisit', Math.round(new Date().getTime() / 1000));
}

getEvents = () => {
	this.setState({initState: 'select'},()=>{
		firestore.collection("events").where("actief", "==", true).get().then(querySnapshot => {
			if(querySnapshot.size > 0){
				var evlist = [];
				querySnapshot.forEach(doc => {
					var eventData = doc.data();
					eventData.eventid = doc.id;
					evlist.push(...[eventData]);					
				});
				this.setState({eventsList: evlist});
			}
		})
	});
}

selectEvent = (data) => {
	this.setState({eventData: data, initState: 'event'},()=>{window.scrollTo(0, 0)});
	localStorage.setItem('lastevent', data.eventid);
}

goDemo = () => {
	firestore.collection("events").doc('f7b6l3OfJibXBut9f0rb').get().then(doc => {
		var eventData = doc.data();
		eventData.eventid = doc.id;
		this.selectEvent(eventData);
	})
}

formatValue = (value) => {return value.toLocaleString("nl-NL")}

}

export default Ppsite;